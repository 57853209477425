<template>
  <div class="product-listing row m0 w-100" :class="isMegaMenu ? 'center-xs' : 'start-xs'">
    <div
      v-for="(product, index) in products"
      :key="product.id + '-' + index"
      class="flex product-listing-box"
      :class="['col-md-' + columns, 'col-xs-' + mobileColumns, {'product-listing-box-row': isRowMode}]"
    >
      <product-tile :is-not-show-hover="isNotShowHover" :product="product" :is-row-mode="isRowMode" :is-main-page="isMainPage" :position="index" :reviews="getReviewByParentId(product.parent_id)" list="catalog" class="bg-cl-white" />
    </div>
  </div>
</template>

<script>
import { prepareRelatedQuery } from '@vue-storefront/core/modules/catalog/queries/related'
import CurrentPage from 'theme/mixins/currentPage'
import ProductTile from 'theme/components/core/ProductTile'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import { createLoadReviewsQuery } from '@vue-storefront/core/modules/review/helpers'

export default {
  name: 'ProductListing',
  mixins: [CurrentPage],
  data () {
    return {
      reviewsMap: {}
    }
  },
  components: {
    ProductTile
  },
  props: {
    products: {
      type: null,
      required: true
    },
    columns: {
      type: [Number, String],
      required: true
    },
    mobileColumns: {
      type: [Number, String],
      required: false,
      default: 6
    },
    isRowMode: {
      type: Boolean,
      required: false,
      default: false
    },
    isMainPage: {
      type: Boolean,
      default: false
    },
    isMegaMenu: {
      type: Boolean,
      default: false
    },
    categoryId: {
      type: Number,
      default: null
    },
    isNotShowHover: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    products: function (newVal, oldVal) {
      if ((newVal.length !== oldVal.length) || (newVal.length === 20 && oldVal.length === 20)) {
        this.refreshList(newVal.length - oldVal.length)
      }
    }
  },
  beforeMount () {
    this.refreshList()
  },
  methods: {
    getReviewByParentId (id) {
      return this.reviewsMap[id]
        ? this.reviewsMap[id]
        : null
    },
    async fetchReviewList (productsIds = [], approved = true, start = 0, size = 100, entityType = 'review', sort = '', excludeFields = ['nickname', 'detail', 'title', 'sgn', 'tsk'], includeFields = null) {
      const query = createLoadReviewsQuery({ productId: productsIds, approved })
      const reviewResponse = await quickSearchByQuery({ query, start, size, entityType, sort, excludeFields, includeFields })
      for (let review of reviewResponse.items) {
        this.reviewsMap[review.product_id]
          ? this.reviewsMap[review.product_id].push(review)
          : this.reviewsMap[review.product_id] = [review]
      }
      if (reviewResponse.items.length === 100) {
        this.fetchReviewList(productsIds, approved, start + size)
      }
    },
    refreshList (lengthDiff) {
      if (!(this.products && this.products.length)) return

      let newProducts = lengthDiff > 20
        ? this.products.slice(-lengthDiff)
        : this.products

      let newProductsIds = []
      for (let product of newProducts) {
        newProductsIds.push(product.parent_id)
      }
      this.fetchReviewList(newProductsIds)
    }
  }
}
</script>

<style lang="scss" scoped>
.product-listing {
  @media (max-width: 767px) {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.product-listing-box-row {
  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0 !important;
    padding-bottom: 2px !important;
  }
}
.product-listing-box {
  padding-bottom: 30px;
  @media (max-width: 767px) {
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: 15px;
    // &:nth-child(even) {
    //   padding-right: 0;
    // }
  }
}
</style>
