<template>
  <div class="desktop-menu w-100 bg-cl-alternative">
    <div class="container px20 h-100">
      <div class="row h-100">
        <div @mouseleave="closeMenu()" class="col-xs-8 flex cl-white">
          <div
            :key="category.slug"
            v-for="category in visibleCategories"
            v-if="visibleCategories && visibleCategories.length"
            class="align-center h4 uppercase nav-link-div"
          >
            <div
              class="global-box"
              @mouseenter="currentOpen(category.id)"
            >
              <div
                class="custom-underline"
                @click="currentOpen('route-enter')"
              >
                <router-link
                  class="nav-link no-underline mx10"
                  :to="categoryLink(category)"
                >
                  {{ category.name }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="global-nav" :class="[{'global-nav-open' : isMenuOpen}, {'global-nav-close' : routeEnter }]">
            <div class="container cl-black px15">
              <div class="row m0 bg-cl-white brdr-left-1 brdr-right-1 brdr-cl-white-smoke" @mouseleave="closeMenu()">
                <div :class="selectedCategoryId === 10 ? 'col-xs-12' : 'col-xs-4 pl0'">
                  <div class="row">
                    <div class="col-xs-12" :class="selectedCategoryId === 10 ? 'marki-box' : 'children-categories-box'">
                      <div v-for="children in categoryByParentId(selectedCategoryId)"
                           :key="children.id"
                           class="children-category brdr-1 brdr-cl-white-smoke"
                           :class="selectedCategoryId === 10 ? 'mx20 my10 width-marki' : ''"
                           @click="currentOpen('route-enter')" @mouseenter="currentOpenChildren(children.id)"
                      >
                        <router-link
                          v-if="children.is_active"
                          class="children-item h5 no-underline block relative"
                          :class="{'py20 pl20' : selectedCategoryId !== 10}"
                          :to="categoryLink(children)"
                        >
                          <img class="marki-img" v-if="selectedCategoryId === 10" :src="getThumbnailPath(children.thumbnail_image)" :alt="children.name">
                          <template v-if="selectedCategoryId !== 10">
                            {{ children.name }}
                          </template>
                          <i class="arrow right absolute" v-if="children.children_count !== '0' && selectedCategoryId !== 10" />
                        </router-link>
                      </div>
                      <div v-if="selectedCategoryId === 54" class="children-category-green bg-cl-forest-green cl-white" @click="currentOpen('route-enter')">
                        <router-link
                          class="children-item h5 no-underline py20 pl20 block relative"
                          :to="localizedRoute('/kategorie-produktow?rightAway=true')"
                        >
                          PRODUKTY DOSTEPNE OD RĘKI
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="selectedCategoryId === 54 || selectedCategoryId === 9" class="col-xs-5 p0">
                  <div class="row">
                    <div class="col-xs-12 children-categories-box">
                      <div v-for="grandChildren in categoryByParentId(currentChildrenNavOpen)"
                           @click="currentOpen('route-enter'); currentOpenChildren('route-enter')"
                           :key="grandChildren.id" class="grand-children-category mt0"
                      >
                        <router-link
                          class="no-underline h5 py20 block grand-children-link"
                          :to="categoryLink(grandChildren)"
                        >
                          {{ grandChildren.name }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="selectedCategoryId !== 10" class="col-xs-3" @click="currentOpen('route-enter'); currentOpenChildren('route-enter')">
                  <lazy-hydrate :trigger-hydration="!loading" v-if="isLazyHydrateEnabledBest">
                    <product-listing columns="12" :mobile-columns="12" :is-mega-menu="true" :products="everythingBestsellerCollection" />
                  </lazy-hydrate>
                  <no-ssr v-else>
                    <product-listing columns="12" :mobile-columns="12" :is-mega-menu="true" :products="everythingBestsellerCollection" />
                  </no-ssr>
                </div>
                <div class="marki-carousel-container container px15" v-if="selectedCategoryId !== 10">
                  <div class="row col-xs-12 around-xs bg-cl-white m0 brdr-1 brdr-cl-white-smoke">
                    <div v-for="(marki, index) in visibleMarki" :key="index + '-' + marki.slug" class="flex middle-xs">
                      <router-link :to="categoryLink(marki)" v-if="marki.is_active">
                        <div @click="currentOpen('route-enter'); currentOpenChildren('route-enter')">
                          <img v-if="isMenuOpen" class="marki-img" v-lazy="getThumbnailPath(marki.thumbnail_image)" :alt="marki.name">
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div @mouseenter="closeMenu()" class="align-center uppercase nav-link-div hidden-md">
            <router-link
              class="nav-link no-underline mx10 opacity-hover"
              :to="localizedRoute('/porady')"
              exact
            >
              Porady
            </router-link>
            <router-link
              class="nav-link no-underline mx10 opacity-hover"
              :to="localizedRoute('/i/wirtualny-spacer')"
              exact
            >
              wirtualny spacer
            </router-link>
          </div>
        </div>
        <div class="col-xs-4 flex middle-xs">
          <div
            v-if="!panelWasHover"
            class="search-input-group middle-xs w-100"
            @mouseenter="hoverPanel()"
          >
            <i class="icon-search cl-white search-icon" />
            <span class="label">Czego szukasz w sklepie nr 1 w Polsce ?</span>
          </div>
          <search-panel-desktop v-if="panelWasHover" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isServer, onlineHelper } from '@vue-storefront/core/helpers'
import LazyHydrate from 'vue-lazy-hydration'
import { mapGetters } from 'vuex'
import config from 'config'
import NoSSR from 'vue-no-ssr'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'
import ProductListing from 'theme/components/core/ProductListing'
import { prepareQuery } from '@vue-storefront/core/modules/catalog/queries/common'
import { Logger } from '@vue-storefront/core/lib/logger'

const SearchPanelDesktop = () => import(/* webpackChunkName: "vsf-search-panel-desktop" */ 'theme/components/core/blocks/SearchPanel/SearchPanelDesktop.vue')

export default {
  name: 'DesktopMenu',
  components: {
    ProductListing,
    SearchPanelDesktop,
    // 'Carousel': () => import('vue-carousel').then(Slider => Slider.Carousel),
    // 'Slide': () => import('vue-carousel').then(Slider => Slider.Slide),
    'no-ssr': NoSSR,
    LazyHydrate
  },
  data () {
    return {
      panelWasHover: false,
      dispatchCounter: 0,
      loading: true,
      currentNavOpen: null,
      currentChildrenNavOpen: null,
      routeEnter: null,
      componentLoaded: false,
      selectedCategoryId: null,
      isMenuOpen: false
    };
  },
  computed: {
    ...mapGetters('homepage', ['getBestsellers']),
    ...mapGetters({
      getCategories: 'category/getCategories'
    }),
    isLazyHydrateEnabledBest () {
      return config.ssr.lazyHydrateFor.some(
        field => ['homepage', 'homepage.bestsellers'].includes(field)
      )
    },
    multistoreEnabled () {
      return config.storeViews.multistore
    },
    everythingBestsellerCollection () {
      return this.$store.state.homepage.bestsellers.slice(0, 1)
    },
    visibleMarki () {
      return this.getCategories.filter(category => {
        return category.parent_id === 10
      })
    },
    visibleCategories () {
      return this.getCategories.filter(category => {
        return category.parent_id === 2
      })
    }
  },
  async asyncData ({ store, route }) {
    Logger.info('Calling asyncData in MegaMenu (theme)')()

    await Promise.all([
      store.dispatch('homepage/loadBestsellers')
    ])
  },
  beforeRouteEnter (to, from, next) {
    if (!isServer && !from.name) { // Loading products to cache on SSR render
      next(vm =>
        vm.$store.dispatch('homepage/loadBestsellers').then(res => {
          vm.loading = false
        })
      )
    } else {
      next()
    }
  },
  methods: {
    hoverPanel () {
      this.panelWasHover = true
    },
    categoryLink (category) {
      return formatCategoryLink(category)
    },
    openMenu () {
      this.isMenuOpen = true
    },
    closeMenu () {
      this.isMenuOpen = false
    },
    categoryByParentId (id) {
      let allCatCopy = this.getCategories
      return allCatCopy.filter(c => { return c.parent_id === id }) // return my child categories
    },
    currentOpenChildren (i) {
      this.$store.dispatch('category/list', { parent: i })
      if (i === 'route-enter') {
        this.routeEnter = 'route-enter'
        this.currentChildrenNavOpen = null
      } else if (isNaN(i)) {
        this.currentChildrenNavOpen = null
        this.routeEnter = null
      } else {
        this.currentChildrenNavOpen = i
      }
    },
    currentOpen (i) {
      this.$store.dispatch('category/list', { parent: 10 })
      this.$store.dispatch('category/list', { parent: i })
      if (this.dispatchCounter === 0) {
        this.$store.dispatch('homepage/loadBestsellers')
        this.dispatchCounter++
      }
      this.isMenuOpen = true
      this.selectedCategoryId = i
      if (i === 'route-enter') {
        this.routeEnter = 'route-enter'
        this.currentNavOpen = null
        this.isMenuOpen = false
      } else if (isNaN(i)) {
        this.isMenuOpen = false
        this.currentNavOpen = null
        this.routeEnter = null
      } else {
        this.currentNavOpen = i
      }
    },
    getThumbnailPath (url) {
      if (url) {
        return _thumbnailHelper(url, 200, 200, 'category')
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss">
.marki-carousel-container {
  .VueCarousel-dot-container {
    margin: 0 !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    .VueCarousel-dot {
      width: 10px !important;
      height: 10px !important;
      outline: none !important;
      padding: 10px 8px !important;
      margin: 0 !important;
      // border-radius: 0 !important
      @media (max-width: 767px) {
        padding: 10px 8px !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-icon-hover: color(secondary, $colors-background);
$primary-orange: color(primary-orange);
$alternative: color(alternative);

.search-input-group {
  z-index: 2;
  display: flex;
  border-bottom: 1px solid #FFFFFF;
  padding-bottom: 7px;
  padding-top: 6px;
  .label {
    overflow: hidden;
    white-space: nowrap;
    color: #b8aaaa;
    background: none;
    padding: 1px 2px;
    width: calc( 100% - 75px);
    line-height: 34px;
    font: 400 13.3333px Arial;
  }
  .search-icon {
    font-size: 21px;
  }
}
.marki-img {
  max-height: 90px;
  width: auto;
  max-width: 90px;
}
.opacity-hover {
  &:hover {
    color: $primary-orange;
  }
}
.global-box {
  &:hover,
  &:focus,
  &:active {
    /* cursor: pointer;*/
    .custom-underline:after {
        width: 100%;
    }
  }
  .custom-underline {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      height: 3px;
      background-color: #ff8100;
      width: 0;
      transition: ease .25s;
    }
  }
}
.global-nav {
  max-height: 600px;
  max-width: 100vw;
  right: 0;
  text-align: left;
  position: absolute;
  left: 0;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  z-index: 9000;
  // background-color: white;
  // transition: opacity .1s linear .4s, visibility 0ms linear .41s;
  &.global-nav-close {
    transition: none !important;
    visibility: hidden;
    transition-delay: 0;
  }
  &.global-nav-open {
    opacity: 1;
    visibility: visible;
    overflow: auto;
    // transition: opacity .1s,visibility 0ms;
    // transition-delay: .3s;
    transition: opacity .1s linear .4s, visibility 0ms linear .41s;
    transition-delay: 0;
  }
}
.marki-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 220px;
  .width-marki {
    height: fit-content;
    border-bottom: 3px solid;
    border-bottom-color: #F5F5F5;
    &:hover {
      border-bottom-color: #041D59;
    }
  }
}
.children-categories-box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 425px;
  max-height: 450px;
  .children-category-green {
    display: flex;
    flex-direction: column;
  }
  .width-marki {
    width: fit-content;
  }
  .children-category {
    display: flex;
    flex-direction: column;
    &:hover {
      background: $alternative;
      color: #FFF;
      .children-item {
        .arrow {
          border-color: #FFF;
        }
      }
    }
    .children-item {
      .arrow {
        border: solid black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 5px;
        right: 20px;
        bottom: 22px;
      }
      .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
  .grand-children-category {
    width: 40%;
    &:hover {
      background: $alternative;
      color: #FFF;
      .children-item {
        .arrow {
          border-color: #FFF;
        }
      }
    }
    .grand-children-link {
      text-transform: none;
    }
  }
}
.nav-link {
  @media (min-width: 768px) {
    font-size: 14px;
    padding: 20px 0;
    display: inline-block;
  }
}
.desktop-menu {
  position: relative;
}
</style>
